<template>
    <div>
        <product-pop-select
            title="选择现用产品"
            :visible.sync="product_select_dialog"
            :table-data="product_table_data"
            :table-button="tableButton"
            :table-columns="product_columns"
            @selectItem="productSelectItem"
            @selcetChange="productSelectChange"
        />

        <selectManufacturer
            :visible.sync="manufacturer_select_dialog"
            :pitch_item.sync="manufacturer_pitch_item"
        />
        <product-pop-select
            title="机器人使用系统"
            :visible.sync="amr_use_system_dialog"
            :table-data="amr_system"
            :table-columns="amr_system_columns"
            :table-button="tableButton"
            @selectItem="amrSystemSelectItem"
            @selcetChange="amrSystemSelectChange"
        />

        <infoHeader :content_name="'新增机器人设备'" />

        <el-form
            ref="form"
            label-width="120px"
            :label-position="'left'"
            :inline="true"
        >
            <el-form-item label="机器人设备编号:">
                <el-input
                    v-model="device_number"
                    @input="v => (device_number = v.replace(/[^\d]/g, ''))"
                    style="width: 300px"
                ></el-input>
            </el-form-item>
        </el-form>

        <el-divider></el-divider>

        <el-form
            ref="form"
            label-width="160px"
            :label-position="'left'"
            :inline="true"
        >
            <el-form-item label="选择设备所属产品系列">
                <el-button type="primary" @click="product_select_dialog = true"
                    >选择</el-button
                >
            </el-form-item>
            <el-form-item
                v-if="product_pitch_item"
                label="已选的产品系列："
                style="float: right"
            >
                <el-tag>型号：{{ product_pitch_item.product_series }}</el-tag>
                <el-tag>产品名：{{ product_pitch_item.product_name }}</el-tag>
            </el-form-item>
        </el-form>

        <el-form
            ref="form"
            label-width="160px"
            :label-position="'left'"
            :inline="true"
        >
            <el-form-item label="选择装配点">
                <el-button
                    type="primary"
                    @click="manufacturer_select_dialog = true"
                    >选择</el-button
                >
            </el-form-item>
            <el-form-item
                v-if="manufacturer_pitch_item"
                label="已选的生产商："
                style="float: right"
            >
                <el-tag
                    >厂商名：{{
                        manufacturer_pitch_item.production_name
                    }}</el-tag
                >
            </el-form-item>
        </el-form>

        <el-form
            ref="form"
            label-width="160px"
            :label-position="'left'"
            :inline="true"
        >
            <el-form-item label="选择机器人使用系统">
                <el-button type="primary" @click="amr_use_system_dialog = true"
                    >选择</el-button
                >
            </el-form-item>
            <el-form-item
                v-if="amr_use_system_item"
                label="已选的机器人系统："
                style="float: right"
            >
                <el-tag
                    >系统名称：{{ amr_use_system_item.product_name }}</el-tag
                >
            </el-form-item>
        </el-form>

        <el-form ref="form" label-width="100px" v-if="amr_use_system_item">
            <el-form-item label="初始AGV状态">
                <el-select v-model="agv_status" placeholder="请选择" clearable>
                    <el-option
                        v-for="item in agv_status_dict"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>

        <el-divider></el-divider>

        <el-form ref="form" label-width="100px">
            <el-button type="primary" @click="sumbitAdd"
                >确认无误，创建新设备</el-button
            >
        </el-form>
    </div>
</template>

<script>
// 载入接口
import { agv_create, agv_status_list } from '@/api/manage/operation/agv/agv.js';
import { product_get } from '@/api/manage/operation/product/product.js';

// 载入方法
// import { 方法名 } from "@/utils/js文件名";
import selectManufacturer from '@/components/selectManufacturer.vue';

import ProductPopSelect from '../productLibrary/components/productPopSelect.vue';

export default {
    name: 'start',
    data() {
        return {
            query: {
                querypage: 1,
                pagesize: 10,
                keyword: ''
            },
            device_number: undefined,
            agv_status_dict: [],
            agv_status: null,
            // 产品所属系列 - 数据配置
            product_select_dialog: false,
            product_pitch_item: null,
            product_table_data: undefined,
            product_columns: [
                // 动态表格列配置
                { prop: 'product_name', label: '产品名' },
                { prop: 'product_series', label: '产品系列' },
                { prop: 'product_type', label: '产品类型' }
            ],
            // 装配点 - 数据配置
            manufacturer_select_dialog: false,
            manufacturer_pitch_item: null,
            // amr使用系统 - 数据配置
            amr_use_system_item: null,
            amr_system: undefined,
            amr_use_system_dialog: false, // 机器人系统弹窗
            amr_system_columns: [
                // 动态表格列配置
                { prop: 'product_name', label: '名称' },
                { prop: 'id', label: 'ID' }
            ],
            tableButton: [{ label: '选择' }]
        };
    },
    components: {
        selectManufacturer,
        ProductPopSelect
    },
    created() {
        this.getInterfaceInformation();
    },
    watch: {
        amr_use_system_item(newVal) {
            if (newVal) {
                this.getAgvStatus(newVal.id);
            }
        }
    },
    methods: {
        getInterfaceInformation() {
            this.getAmrSysteam();
            this.getProduct();
        },
        sumbitAdd() {
            // 参数和错误提示映射
            const validations = [
                {
                    condition:
                        !this.product_pitch_item || !this.product_pitch_item.id,
                    message: '请选择设备所属产品系列'
                },
                {
                    condition:
                        !this.manufacturer_pitch_item ||
                        !this.manufacturer_pitch_item.id,
                    message: '请选择装配点'
                },
                { condition: !this.agv_status, message: '请选择初始AGV状态' },
                {
                    condition:
                        !this.amr_use_system_item ||
                        !this.amr_use_system_item.id,
                    message: '请选择机器人使用系统'
                },
                { condition: !this.device_number, message: '请输入机器人设备编号' }
            ];

            // 遍历检查条件
            for (const { condition, message } of validations) {
                if (condition) {
                    this.$message.error(message);
                    return;
                }
            }

            // 参数齐全，提交数据
            agv_create({
                product_id: this.product_pitch_item.id,
                manufacturer_id: this.manufacturer_pitch_item.id,
                agv_status: this.agv_status,
                software_product_id: this.amr_use_system_item.id,
                device_number: this.device_number
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success('新增成功');
                    this.$router.push({ name: 'agv_Manage' });
                } else {
                    this.$message.info('新增失败');
                }
            });
        },
        getAmrSysteam(ids) {
            product_get({ product_class_id: ids }).then(res => {
                this.amr_system = res.data.result;
            });
        },
        getAgvStatus(ids) {
            agv_status_list({
                software_product_id: ids
            }).then(res => {
                //Object.entries(): 返回一个数组，其中每个元素都是一个包含对象键和值的数组。
                this.agv_status_dict = Object.entries(res.data).map(
                    ([key, value]) => {
                        return { value: Number(key), label: value };
                    }
                );
            });
        },
        amrSystemSelectItem(item) {
            this.amr_use_system_item = item;
        },
        amrSystemSelectChange(ids) {
            this.getAmrSysteam(ids);
        },
        getProduct(ids) {
            product_get({ product_class_id: ids }).then(res => {
                this.product_table_data = res.data.result;
            });
        },
        productSelectItem(item) {
            this.product_pitch_item = item;
        },
        productSelectChange(ids) {
            this.getProduct(ids);
        }
    }
};
</script>

<style lang="scss" scoped></style>
