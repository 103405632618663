<template>
  <div>
    <el-dialog title="现有产品" :visible.sync="visible_dialog" width="80%" @close="handleClose">
    <el-table :data="tableData">
        <el-table-column prop="production_name" label="生成点"></el-table-column>
        <el-table-column label="更多" fixed="right" width="140">
            <template slot-scope="scope">
            <el-button @click="tpSelectItem(scope.row)">选择</el-button>
            </template>
        </el-table-column>
    </el-table>

    </el-dialog>
  </div>
</template>
<script>
import { manufacturer_get } from "@/api/manage/operation/product/manufacturer.js";
export default {
  name: "selectProduct",
    props: ["visible", "pitch_item"],
    created() {
        this.getQueryData() // 定义该函数在页面一加载时就执行
    },
    data() {
        return {
        visible_dialog: null,
        tableData: [],
        };
    },
    components: { },
    watch: {
        visible() {
            this.visible_dialog = this.visible;
        },
    },
    methods: {
        getQueryData() {
        this.$http(
            manufacturer_get({
            }),
            (res) => {
            console.log(res);
            if (res.code == 200) {
                this.tableData = res.data;
            } else {
                this.$message({
                message: res.msg,
                type: "error",
                duration: 5 * 1000,
                });
            }
            }
        );
        },
        handleClose() {
            this.$emit("update:visible", false);
        },
        tpSelectItem(items) {
            this.$emit("update:pitch_item", items);
            // console.log(items)
            this.visible_dialog = false
            this.handleClose()
        }
    }
};
</script>